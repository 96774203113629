import React from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Input } from "./components/Input";
import { Button } from "./components/Button";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = () => {
    fetch("https://api.eule.ai/admin/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          localStorage.setItem("token", data.token);
          navigate("/dashboard");
        }
      });
  };

  return (
    <div>
      <img
        src="/neon_city.webp"
        className="fixed w-full h-full blur-2xl opacity-80"
        alt="neon city"
      />
      <div className="fixed top-0 left-0 w-full h-full flex flex-col gap-4 items-center justify-center">
        <div className="flex gap-4 items-center">
          <FontAwesomeIcon
            icon={faUser}
            className="h-12 p-4 bg-blue-500 bg-opacity-60 rounded-full"
          />
        </div>
        <div className="flex flex-col gap-4 w-96 max-w-[90%] h-fit bg-brand-black rounded-lg bg-opacity-60 backdrop-blur-xl p-4">
          <div className="flex flex-col gap-2">
            <Input
              value={username}
              placeholder="Username"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <Input
              value={password}
              placeholder="Password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>

          <Button label="LOGIN" onClick={login} className="font-bold text-xl" />
        </div>
      </div>
    </div>
  );
}

export default App;
