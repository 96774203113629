import { faHouse, faSliders, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SidebarLink } from "./SidebarLink";

export const Sidebar = ({ activeTab, setActiveTab }) => {
  return (
    <div className="h-[100vh] min-w-[16rem] w-64 bg-brand-gray px-4">
      <div className="flex flex-col items-center justify-center text-xl font-bold gap-2 w-full h-32 border-b-[1px] border-gray-500">
        <FontAwesomeIcon
          icon={faUser}
          className="h-8 w-8 p-4 bg-blue-500 bg-opacity-60 rounded-full"
        />
        Admin
      </div>

      <div className="flex flex-col h-fit w-full py-4 gap-2">
        <SidebarLink
          label="Dashboard"
          icon={faHouse}
          onClick={() => {
            setActiveTab("dashboard");
          }}
          active={activeTab === "dashboard"}
        />
        <SidebarLink
          label="Controls"
          icon={faSliders}
          onClick={() => {
            setActiveTab("controls");
          }}
          active={activeTab === "controls"}
        />
      </div>
    </div>
  );
};
