import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

export const SidebarLink = ({ label, icon, active, onClick }) => {
  return (
    <div
      className={classNames(
        "flex items-center gap-4 p-3 rounded-md cursor-pointer hover:bg-blue-500 hover:bg-opacity-20",
        {
          "bg-blue-500 bg-opacity-20": active,
        }
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} className="h-6 w-6" />
      <span className="text-lg">{label}</span>
    </div>
  );
};
