import React from "react";

export const Input = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  type = "text",
}) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-xs opacity-80">{label}</label>
      <input
        className="border-[1px] border-gray-400 rounded-md p-2 text-text outline-none bg-transparent"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        onBlur={onBlur}
      />
    </div>
  );
};
