import React from "react";

export const InfoCard = ({ label, value, loading }) => {
  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center text-center bg-brand-gray rounded-md">
      {!loading && (
        <div className="text-4xl font-bold px-8 py-2 bg-brand-black rounded-full">
          {value}
        </div>
      )}
      <div className="absolute bottom-4 opacity-80 text-xl">{label}</div>
    </div>
  );
};
