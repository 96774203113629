import React from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../components/sidebar/Sidebar";
import { ControlsTab } from "../components/tabs/ControlsTab";
import { DashboardTab } from "../components/tabs/DashboardTab";

export const Dashboard = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [activeTab, setActiveTab] = React.useState("dashboard");

  React.useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      fetch("https://api.eule.ai/admin/verify_token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "error") {
            navigate("/");
          }
        });
    }
  });

  return (
    <div className="flex">
      <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === "dashboard" && <DashboardTab />}
      {activeTab === "controls" && <ControlsTab />}
    </div>
  );
};
