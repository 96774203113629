import classNames from "classnames";
import React from "react";

export const Button = ({ label, onClick, className = "" }) => {
  return (
    <button
      className={classNames(
        className,
        "bg-blue-500 text-white p-2 rounded-md hover:bg-opacity-80 duration-100"
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
