import React from "react";
import { InfoCard } from "./quarks/InfoCard";

export const DashboardTab = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch("https://api.eule.ai/admin/dashboard", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        console.log(data);
      });
  }, []);

  return (
    <div className="w-full p-8">
      <div className="flex gap-2 h-52 w-full">
        <InfoCard
          label="Users"
          value={data && data.info.users_count}
          loading={data === null}
        />

        <InfoCard
          label="Files"
          value={data && data.info.total_files_count}
          loading={data === null}
        />

        <InfoCard
          label="Saved Queries"
          value={data && data.info.total_histories_count}
          loading={data === null}
        />
      </div>
    </div>
  );
};
