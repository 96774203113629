import { Switch } from "@mui/material";
import React from "react";
import { Input } from "../Input";

export const ControlsTab = () => {
  const token = localStorage.getItem("token");

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch("https://api.eule.ai/admin/controls", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setData(data.info);
          console.log(data.info);
        }
      });
  }, []);

  const toggleMaintenance = () => {
    fetch("https://api.eule.ai/admin/toggle_maintenance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    });
  };

  return (
    <div className="w-full p-8">
      <div className="w-fit h-40 bg-brand-gray rounded-md p-2">
        <div className="flex items-center gap-2">
          <Switch
            checked={data ? data.maintenance : false}
            onClick={() => {
              if (data) {
                toggleMaintenance();
                let new_data = Object.assign({}, data);
                new_data.maintenance = !new_data.maintenance;
                setData(new_data);
              }
            }}
          />
          Maintenance Mode
        </div>
        <Input
          label="Maintenance Key"
          value={data && data.maintenance_key}
          onChange={(e) => {
            let new_data = Object.assign({}, data);
            new_data.maintenance_key = e.target.value;
            setData(new_data);
          }}
          onBlur={() => {
            fetch("https://api.eule.ai/admin/update_maintenance_key", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                maintenance_key: data.maintenance_key,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};
